import React, { useEffect, useState } from "react"
import Layout from "../components/Layout"
import SecondaryHeader from "../components/SecondaryHeader"
import Seo from "../components/Seo"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import { Helmet } from "react-helmet"
import { GatsbySeo } from "gatsby-plugin-next-seo"

const AboutUsPage = ({ data }) => {
  return (
    <Layout>
      <GatsbySeo
        title="description"
        description="About AlignerBase: Revolutionizing orthodontics with innovation, technology, and customer service. Affordable, high-quality aligner setups for dental professionals."
      />
      <Helmet>
        <meta charset="utf-8" />
      </Helmet>
      <Seo title={`About us`} />
      <SecondaryHeader title={`About us`} />

      <div id="about-us-page">
        <section className="main-section">
          <div className="main-section__text">
            <p>
              At AlignerBase, we are focused on transforming orthodontics
              through innovation, cutting-edge technology, and unparalleled
              customer service. Our B2B product is designed to make it
              affordable for dentists, dental labs, and aligner companies to
              outsource aligner setups without compromising treatment quality,
              allowing doctors to maximize time spent on patient care.
            </p>

            <p>
              We understand the importance of precision and accuracy in
              designing treatments, which is why we only use the most advanced
              and FDA-approved orthodontic CAD software to create treatment
              plans customized to each patient's treatment needs and expected
              outcomes. Our team of experienced orthodontists and technicians
              are equipped with AI-enabled support tools to improve treatment
              predictability, reduce refinement and revision rates, and deliver
              significant savings in time and cost.
            </p>

            <p>
              We have spent countless hours with leading orthodontists
              developing our secure dentists' portal with two central goals:
              Transparency and Collaboration. The AlignerBase Platform ensures
              transparency by letting you track treatment plans at each stage of
              the design workflow, communicate directly with your design team at
              any point, and request as many plan revisions as needed to achieve
              treatment plan perfection.
            </p>

            <p>
              For now, we seek to empower dental professionals and businesses to
              provide the highest quality orthodontic care to their patients and
              to make the process of achieving a perfect smile more accessible
              and affordable for everyone. In the future, we commit ourselves to
              continue making significant investments in our technology and
              capabilities to push the boundaries of what is possible in digital
              orthodontics, while expanding the breadth and depth of our
              products and services.
            </p>
          </div>
          <div className="main-section__img-wrapper">
            <Img fluid={data.mainImg.childImageSharp.fluid} />
          </div>
        </section>

        <div className="divider-large" />
        <div className="custom-section">
          <Img fluid={data.missionImg.childImageSharp.fluid} />
          <h3>Mission</h3>
          <p>
            To empower doctors and improve patient outcomes through innovative
            and cost-effective healthcare technology.
          </p>
        </div>

        <div className="divider-large" />

        <div className="custom-section">
          <Img fluid={data.visionImg.childImageSharp.fluid} />

          <h3>Vision</h3>
          <p>
            A world where high-quality treatment options are accessible and
            affordable to all patients, without geographical boundaries.
          </p>
        </div>

        <div className="divider-large" />
        <div className="addressContainer">
          <div className="addressSubContainer">
            <h5 className="addressContainer__addressSubContainer__heading">
              Main Office:
            </h5>
            <div className="addressContainer__addressSubContainer__address">
              <Img
                style={{ width: "47px", height: "47px", marginRight: "5px" }}
                fluid={data.usaFlag.childImageSharp.fluid}
                alt="USA Flag"
                className="flag"
              />
              <div>
                AlignerBase LLC 1007 N Orange St 4th Floor Suite 1357
                Wilmington,
                <br />
                DE 19801 United States
              </div>
            </div>
          </div>
          <div className="addressSubContainer">
            <h5 className="addressContainer__addressSubContainer__heading">
              Regional Office:
            </h5>
            <div className="addressContainer__addressSubContainer__address">
              <Img
                style={{ width: "47px", height: "47px", marginRight: "5px" }}
                fluid={data.bahrainFlag.childImageSharp.fluid}
                alt="Bahrain Flag"
                className="flag"
              />
              <div>
                Office 225, Building 190, Road 2803, Block 428 Al Seef,
                <br /> Kingdom of Bahrain
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default AboutUsPage

export const query = graphql`
  query {
    mainImg: file(relativePath: { eq: "alignerbase-about-main-img.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    missionImg: file(relativePath: { eq: "alignerbase-about-mission.png" }) {
      childImageSharp {
        fluid(maxWidth: 150) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    visionImg: file(relativePath: { eq: "alignerbase-about-vision.png" }) {
      childImageSharp {
        fluid(maxWidth: 150) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bahrainFlag: file(relativePath: { eq: "Flag-Bahrain.webp" }) {
      childImageSharp {
        fluid(maxWidth: 150) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    usaFlag: file(relativePath: { eq: "unitedStates.png" }) {
      childImageSharp {
        fluid(maxWidth: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
